//
// Prismjs
//

$base-color: #1e1e3f;

// highlight
.highlight {
    position: relative;
    background: $base-color;
    border-radius: $border-radius;
    padding: 1.75rem 1.5rem 1.75rem 1.5rem;

    // Tabs
    .nav {
        border-bottom: 1px solid rgba(#ffffff, 0.1);
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        margin-top: -0.25rem;

        .nav-item {
            margin-right: 0.75rem;
        }

        .nav-link {
            font-size: 1rem;
            font-weight: 500;
            padding: 0.35rem 1rem;
            border-radius: $border-radius;
            color: rgba($white, 0.8);
            transition: $transition-base;
            background-color: transparent;

            &:focus,
            &.active {
                transition: $transition-base;
                background-color: rgba(#ffffff, 0.1);
                olor: $white;
            }
        }
    }

    // Tab content
    .tab-content {

    }

    .highlight-copy {
        display: none;
        position: absolute;
        right: 1.75rem;
        top: 1.5rem;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0.35rem 1rem !important;
        transition: $transition-base;
        background-color: rgba(#ffffff, 0.1);
        color: rgba($white, 0.8);

        &:focus,
        &:hover {
            transition: $transition-base;
            background-color: rgba(#ffffff, 0.2);
            color: $white;
        }
    }

    &:hover {
        .highlight-copy {
            display: flex;
        }
    }

    // Code
    .highlight-code {
        pre {
            overflow: auto;
            padding: 0;
            margin: 0;

            &::-webkit-scrollbar {
                width: .5rem;
                border-radius: .25rem;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $gray-100;
            }

            code[class*="language-"] {
                padding: 0;
                margin: 0;
                font-size: 1rem !important;
            }
        }
    }
}
